import Vue from "vue";
import VueRouter from "vue-router";
import homePage from "../views/homePage.vue";
import indexPage from "../views/indexPage.vue";
import aboutPage from "../views/aboutPage.vue";
import contactPage from "../views/contactPage.vue";
import cooperationPage from "../views/cooperationPage.vue";
import culturePage from "../views/culturePage.vue";
import factoryPage from "../views/factoryPage.vue";
import proDetail from "../views/proDetail.vue";
import proList from "../views/proList.vue";
import detailPage from "../views/detailPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: homePage,
    children: [
      { path: "/indexPage", redirect: "/" },
      { path: "/", name: "homePage",component: indexPage },
      { path: "indexPage", name: "indexPage", component: indexPage },
      { path: "aboutPage", name: "aboutPage", component: aboutPage },
      { path: "contactPage", name: "contactPage", component: contactPage },
      {
        path: "cooperationPage",
        name: "cooperationPage",
        component: cooperationPage,
      },
      { path: "culturePage", name: "culturePage", component: culturePage },
      { path: "factoryPage", name: "factoryPage", component: factoryPage },
      { path: "proDetail/:id", name: "proDetail", component: proDetail },
      { path: "proList", name: "proList", component: proList },
      { path: "detailPage/:id", name: "detailPage", component: detailPage },
    ],
  },
];

const router = new VueRouter({
  // mode: 'history',
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

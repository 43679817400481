<template>
  <div id="proList">
    <div
      class="transparent-bg-one"
      :style="{ 'background-image': 'url(' + apiUrl + banner.adImage + ')' }"
    >
      <div class="page-inner">
        <div class="transparent-bg-one-inner">
          <div class="transparent-bg-one-inner-title">
            <span v-if="isCN">{{ banner.title }}</span>
            <span v-else>{{ banner.titleEn }}</span>
          </div>
          <p>
            <span v-if="isCN">{{ banner.detail }}</span>
            <span v-else>{{ banner.detailEn }}</span>
          </p>
          <div class="transparent-bg-one-btn">{{ $t("useful.read") }}</div>
        </div>
      </div>
    </div>
    <div class="transparent-white-one">
      <div class="page-inner">
        <div class="transparent-white-one-title">
          {{ $t("useful.proList") }}
        </div>
        <div class="transparent-white-one-inner">
          <div
            class="transparent-white-one-box"
            v-for="(item, i) in list"
            :key="i"
          >
            <img
              :src="apiUrl + item.productImage"
              class="transparent-white-one-img"
            />
            <div class="transparent-white-one-info">
              <div class="transparent-white-one-name">
                <span v-if="isCN">{{ item.title }}</span>
                <span v-else>{{ item.titleEn }}</span>
                <div class="transparent-white-one-desc">
                  {{ item.viewCount }} {{ $t("useful.view") }}
                  <div
                    @click="$router.push('/proDetail/' + item.productId)"
                    class="transparent-white-one-desc-right"
                  >
                    {{ $t("useful.read") }}
                    <img src="../assets/img/arrow-right-cycle.png" />
                  </div>
                </div>
              </div>
              <div class="pro-desc mobile-hide">
                <span v-if="isCN">{{ item.summary }}</span>
                <span v-else>{{ item.summaryEn }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="transparent-white-one-title">
          {{ $t("useful.proApply") }}
        </div>
        <div class="transparent-white-one-inner">
          <div
            class="transparent-white-one-box"
            v-for="(item, i) in applyList"
            :key="i"
          >
            <img
              :src="apiUrl + item.productImage"
              class="transparent-white-one-img"
            />
            <div class="transparent-white-one-info">
              <div class="transparent-white-one-name">
                <span v-if="isCN">{{ item.title }}</span>
                <span v-else>{{ item.titleEn }}</span>
                <div class="transparent-white-one-desc">
                  {{ item.viewCount }} {{ $t("useful.view") }}
                  <div
                    @click="$router.push('/proDetail/' + item.productId)"
                    class="transparent-white-one-desc-right"
                  >
                    {{ $t("useful.read") }}
                    <img src="../assets/img/arrow-right-cycle.png" />
                  </div>
                </div>
              </div>
              <div class="pro-desc mobile-hide">
                <span v-if="isCN">{{ item.summary }}</span>
                <span v-else>{{ item.summaryEn }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { getAdInfoByType, getProductList } from "@/request/Api";

export default {
  name: "proList",
  data() {
    return {
      isCN: true,
      banner: {},
      list: [],
      applyList: [],
    };
  },
  mounted() {
    if (localStorage.getItem("lang") == "cn") {
      this.isCN = true;
    } else {
      this.isCN = false;
    }
    this.$EventBus.$on("changeLang", (res) => {
      if (res == "cn") {
        this.isCN = true;
      } else {
        this.isCN = false;
      }
    });

    this.getData();
  },
  methods: {
    getData() {
      getAdInfoByType({
        adType: "PRODUCT",
      }).then((res) => {
        this.banner = res.data;
      });
      getProductList().then((res) => {
        this.list = [];
        this.applyList = [];
        for (let i = 0; i < res.rows.length; i++) {
          if (res.rows[i].productType == "PRODUCT") {
            this.list.push(res.rows[i]);
          } else {
            this.applyList.push(res.rows[i]);
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.transparent-bg-one .page-inner {
  display: flex;
}

.transparent-bg-one {
  background-repeat: no-repeat;
  background-size: cover;
}

.transparent-bg-one-inner {
  width: 55%;
  text-indent: 32px;
  color: #fff;
  padding-left: 15px;
  margin: 123px 0 112px;
}

.transparent-bg-one-inner-title {
  position: relative;
  animation: fadeIn 2s;
  text-shadow: 0px 2px 18px #464747;
  text-indent: 0;
  font-size: 63px;
}

.transparent-bg-one-inner p {
  line-height: 26px;
  text-shadow: 0px 2px 18px #464747;
  font-size: 16px;
  margin-top: 30px;
}

.transparent-bg-one-btn {
  width: 155px;
  line-height: 49px;
  text-align: center;
  text-indent: 0;
  transition: 0.3s;
  background: linear-gradient(0deg, #458f42, #458f42, #458f42);
  box-shadow: 0px 2px 18px 0px #464747;
  border-radius: 25px;
  margin-top: 50px;
  cursor: pointer;
  user-select: none;
}

.transparent-bg-one-btn:hover {
  opacity: 0.8;
}

.transparent-white-one {
  background: url(../assets/img/index-boxbg.png) no-repeat;
  background-size: cover;
  padding: 62px 0 33px;
}

.transparent-white-one-title {
  text-align: center;
  color: #00411f;
  font-size: 48px;
  margin-bottom: 58px;
}

.transparent-white-one-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.transparent-white-one-box {
  width: 284px;
  overflow: hidden;
  transition: 0.3s;
  background-color: #f9f9f9;
  border-radius: 10px;
  margin: 0 19px 38px;
}

.transparent-white-one-box:hover {
  transform: translateY(-5px);
  box-shadow: 1px 2px 29px 0px rgba(0, 0, 0, 0.15);
}

.transparent-white-one-img {
  width: 100%;
  height: 177px;
  object-fit: cover;
}

.transparent-white-one-info {
  line-height: 19px;
  color: #666;
  font-size: 14px;
  padding: 14px 22px 22px;
}

.transparent-white-one-name {
  color: #000;
  font-size: 18px;
  font-weight: bold;
}

.transparent-white-one-desc {
  position: relative;
  color: #999;
  font-size: 14px;
  font-weight: 400;
  margin: 4px 0 14px;
}

.transparent-white-one-desc-right {
  position: absolute;
  right: 0;
  top: 0;
  color: #458f42;
  cursor: pointer;
  user-select: none;
}

.transparent-white-one-desc-right:hover {
  text-decoration: underline;
}

.transparent-white-one-desc-right img {
  width: 11px;
  height: 11px;
  object-fit: contain;
}
.pro-desc {
  text-indent: 28px;
}
@media screen and (max-width: 600px) {
  .white-bg-one {
    padding: 30px 15px 40px;
  }

  .transparent-bg-one .page-inner {
    display: block;
  }

  .transparent-bg-one {
    padding: 30px 15px 40px;
  }

  .transparent-bg-one-inner {
    width: calc(100% - 30px);
    text-indent: 28px;
    margin: 0;
  }

  .transparent-bg-one-inner-title {
    font-size: 33px;
  }

  .transparent-bg-one-inner p {
    line-height: 24px;
    font-size: 14px;
  }

  .transparent-bg-one-btn {
    width: 130px;
    line-height: 40px;
  }

  .transparent-white-one {
    padding: 30px 0 15px;
  }

  .transparent-white-one-title {
    font-size: 28px;
    margin-bottom: 30px;
  }

  .transparent-white-one-inner {
    display: block;
    padding: 0 15px;
  }

  .transparent-white-one-box {
    width: calc(49% - 7px);
    display: inline-block;
    vertical-align: top;
    margin: 0 0 15px;
  }
  .transparent-white-one-box:nth-child(2n) {
    margin-left: 19px;
  }
  .transparent-white-one-img {
    height: 100px;
  }

  .transparent-white-one-info {
    padding: 10px 15px;
  }

  .transparent-white-one-name {
    font-size: 16px;
  }

  .transparent-white-one-desc {
    margin: 2px 0 0;
  }

  .transparent-white-one-desc-right {
    display: block;
    position: relative;
    text-decoration: underline;
    margin-top: 6px;
  }
}
</style>
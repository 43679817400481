<template>
  <div id="detailPage">
    <div class="about-white-one">
      <div class="page-inner">
        <div v-if="info && isCN" v-html="info.detail"></div>
        <div v-else-if="info && !isCN" v-html="info.detailEn"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getArticleById } from "@/request/Api";

export default {
  name: "detailPage",
  data() {
    return {
      isCN: true,
      id: "",
      info: {},
    };
  },
  mounted() {
    if (localStorage.getItem("lang") == "cn") {
      this.isCN = true;
    } else {
      this.isCN = false;
    }
    this.$EventBus.$on("changeLang", (res) => {
      if (res == "cn") {
        this.isCN = true;
      } else {
        this.isCN = false;
      }
    });

    this.id = this.$route.params.id;
    this.getData();
  },
  methods: {
    getData() {
      getArticleById({
        articleId: this.id,
      }).then((res) => {
        this.info = res.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.about-white-one {
  background-color: #fff;
  padding: 68px 0;
}
.about-white-one img {
  width: 100%;
}
@media screen and (max-width: 600px) {
  .about-white-one {
    padding: 15px;
  }
}
</style>

<template>
  <div id="culturePage">
    <div
      class="culture-banner"
      :style="{ 'background-image': 'url(' + apiUrl + banner.adImage + ')' }"
    >
      <div class="culture-banner-inner">
        <div class="culture-banner-title">
          <span v-if="isCN">{{ banner.title }}</span>
          <span v-else>{{ banner.titleEn }}</span>
          <br />
          <span v-if="isCN">{{ banner.summary }}</span>
          <span v-else>{{ banner.summaryEn }}</span>
        </div>
        <span v-if="isCN">{{ banner.detail }}</span>
        <span v-else>{{ banner.detailEn }}</span>
      </div>
    </div>
    <div class="culture-bg">
      <div class="page-inner">
        <div class="culture-title">
          <span v-if="isCN">我们的企业文化</span>
          <div v-else class="culture-title-en">Our Corporate Culture</div>
        </div>
        <div class="culture-white">
          <div class="culture-box">
            <img src="../assets/img/culture-icon1.png" />
            <div class="culture-box-title">
              <span v-if="isCN">创新</span>
              <span v-else>INNOVATION</span>
            </div>
            <!-- <div class="culture-box-title-en"></div> -->
            <div v-if="isCN" class="culture-box-text">
              不断创造新产品、新体验、新价值
            </div>
            <div v-else class="culture-bot">
              <p>Constantly create new products</p>
              <p>new experiences</p>
              <p>new value</p>
            </div>
          </div>
          <div class="culture-box">
            <img src="../assets/img/culture-icon2.png" />
            <div class="culture-box-title">
              <span v-if="isCN">精益</span>
              <span v-else>EXCELSIOR</span>
            </div>
            <!-- <div class="culture-box-title-en"></div> -->
            <div v-if="isCN" class="culture-box-text">
              精益思想、精益管理、精益生产
            </div>
            <div v-else class="culture-bot">
              <p>Lean thinking</p>
              <p>lean management</p>
              <p>lean production</p>
            </div>
          </div>
          <div class="culture-box">
            <img src="../assets/img/culture-icon3.png" />
            <div class="culture-box-title">
              <span v-if="isCN">诚信</span>
              <span v-else>INTEGRITY</span>
            </div>
            <!-- <div class="culture-box-title-en"></div> -->
            <div v-if="isCN" class="culture-box-text">
              深耕行业、以诚信立业、恪守商业操守
            </div>
            <div v-else class="culture-bot">
              <p>Deep cultivation in the industry</p>
              <p>establish business with integrity</p>
              <p>abide by business ethics</p>
            </div>
          </div>
          <div class="culture-box">
            <img src="../assets/img/culture-icon4.png" />
            <div class="culture-box-title">
              <span v-if="isCN">感恩</span>
              <span v-else>GRATEFULNESS</span>
            </div>
            <!-- <div class="culture-box-title-en"></div> -->
            <div v-if="isCN" class="culture-box-text">
              谨记企业对员工、合作伙伴和社会的责任，回馈社会
            </div>
            <div v-else class="culture-bot">
              <p>Remember your responsibility to employees</p>
              <p> partners and society and give back to the community</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAdInfoByType } from "@/request/Api";

export default {
  name: "culturePage",
  data() {
    return {
      isCN: true,
      banner: {},
    };
  },
  mounted() {
    if (localStorage.getItem("lang") == "cn") {
      this.isCN = true;
    } else {
      this.isCN = false;
    }
    this.$EventBus.$on("changeLang", (res) => {
      if (res == "cn") {
        this.isCN = true;
      } else {
        this.isCN = false;
      }
    });

    this.getData();
  },
  methods: {
    getData() {
      getAdInfoByType({
        adType: "CULTURE",
      }).then((res) => {
        this.banner = res.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.culture-banner {
  text-align: center;
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 223px 0 334px;
}

.culture-banner-inner {
  width: 837px;
  line-height: 22px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
}

.culture-banner-title {
  line-height: 74px;
  font-size: 63px;
  margin-bottom: 32px;
}

.culture-bg {
  text-align: center;
  background: url(../assets/img/culture-bg.png) no-repeat;
  background-size: cover;
  padding: 75px 0 87px;
}

.culture-title {
  color: #000;
  font-size: 30px;
}

.culture-title-en {
  color: #00411f;
  font-size: 48px;
}

.culture-white {
  display: flex;
  background-color: #fff;
  margin-top: 60px;
}

.culture-box {
  width: 25%;
  line-height: 20px;
  transition: 0.3s;
  color: #666;
  border-right: 1px solid #eee;
  font-size: 12px;
}

.culture-box:last-child {
  border-right: 0;
}

.culture-box:hover {
  box-shadow: 1px 2px 29px 0px rgba(0, 0, 0, 0.15);
}

.culture-box img {
  margin: 78px 0 22px;
}

.culture-box-title {
  color: #69a21d;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 5px;
}

.culture-box-title-en {
  font-size: 18px;
}

.culture-box-text {
  width: 234px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  margin: 42px 0 68px;
}

.culture-bot {
  margin: 42px 0 68px;
}

@media screen and (max-width: 600px) {
  .culture-banner {
    padding: 30px;
  }

  .culture-banner-inner {
    width: 100%;
  }

  .culture-banner-title {
    line-height: 40px;
    font-size: 36px;
    margin-bottom: 32px;
  }

  .culture-bg {
    padding: 30px 0;
  }

  .culture-title {
    font-size: 20px;
  }

  .culture-title-en {
    font-size: 36px;
  }

  .culture-white {
    display: block;
    background-color: transparent;
    margin-top: 30px;
  }

  .culture-box {
    width: 80%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border-right: 0;
    margin-bottom: 15px;
  }

  .culture-box img {
    margin: 30px 0 5px;
  }

  .culture-box-title {
    font-size: 22px;
  }

  .culture-box-text {
    width: 80%;
    padding-bottom: 30px;
    margin: 22px 0 18px;
  }

  .culture-bot {
    padding-bottom: 30px;
    margin-bottom: 18px;
  }
}
</style>

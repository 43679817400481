<template>
  <div id="aboutPage">
    <img :src="apiUrl + list[0].image" class="about-banner" />
    <div class="about-white-one">
      <div class="page-inner">
        <div v-if="list[0] && isCN" v-html="list[0].detail"></div>
        <div v-else-if="list[0] && !isCN" v-html="list[0].detailEn"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getArticleListByType } from "@/request/Api";

export default {
  name: "aboutPage",
  data() {
    return {
      isCN: true,
      list: [],
    };
  },
  mounted() {
    if (localStorage.getItem("lang") == "cn") {
      this.isCN = true;
    } else {
      this.isCN = false;
    }
    this.$EventBus.$on("changeLang", (res) => {
      if (res == "cn") {
        this.isCN = true;
      } else {
        this.isCN = false;
      }
    });

    this.getData();
  },
  methods: {
    getData() {
      getArticleListByType({
        articleType: "ABOUTUS",
      }).then((res) => {
        this.list = res.rows;
        this.list[0].detail = this.list[0].detail.replace(
          /\/prod-api/g,
          "/api"
        );
        this.list[0].detailEn = this.list[0].detailEn.replace(
          /\/prod-api/g,
          "/api"
        );
      });
    },
  },
};
</script>
<style lang="less" scoped>
.about-banner {
  width: 100%;
}
.about-white-one {
  background-color: #fff;
  padding: 68px 0;
}
/deep/ .about-white-one p {
  line-height: 28px;
}
/deep/ .about-white-one img {
  max-width: 100%;
  margin: 10px 0;
}
@media screen and (max-width: 600px) {
  .about-white-one {
    padding: 15px;
  }
}
</style>

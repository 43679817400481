// 中文语言包
import cnLocale from "element-ui/lib/locale/lang/zh-CN"; // 中文
export default {
  ...cnLocale,
  navBar: {
    index: "首页",
    product: "公司产品",
    about: "关于我们",
    culture: "企业文化",
    factory: "工厂实景",
    cooperation: "合作客户",
    contact: "联系我们",
    main: "主菜单",
    copyright: "版权所有",
    gusher: "古水",
    cookie: "Cookie协议",
    privacy: "隐私协议",
    terms: "条款和协议",
    commit: "无障碍承诺",
    mainBusiness: "主营",
    transparent: "透明PETG热收缩膜",
    white: "乳白色PETG热收缩膜",
    custom: "从这里开始自定义您的项目",
    menu: "菜单",
  },
  useful: {
    read: "查看更多",
    view: "查看",
    proList: "产品列表",
    proApply: "产品应用",
    consult: "点击咨询",
    description: "描述",
    application: "应用",
    contact: "联系信息",
    name: "姓名",
    phone: "电话",
    email: "邮箱",
    require: "请说明您的要求",
    submit: "提交",
    enter: "请输入",
    instructions: "产品详情及说明",
    total: "共",
    tiao: "条",
    page: "页",
    firstPage: "首页",
    lastPage: "末页",
    sincere: "真诚合作",
    mobile: "手机",
    address: "地址",
    code: "二维码",
    scan: "点击扫描二维码",
  },
  pro: {
    intro: "产品介绍",
    apply: "主要应用",
    specifi: "产品规格",
    num: "接头数量",
    core: "纸芯内径",
  },
};

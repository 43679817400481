<template>
  <div id="proDetail">
    <div class="page-pop" id="detailPop">
      <div class="pop-mask" @click="closeDetail"></div>
      <div class="pop-detail">
        <div class="pop-detail-title">
          <div class="pop-detail-title-cycle"></div>
          {{ $t("useful.instructions") }}
          <img
            src="../assets/img/icon-close.png"
            class="pop-detail-close"
            @click="closeDetail"
          />
        </div>
        <div class="pop-detail-inner">
          <img :src="apiUrl + info.productImage" class="pop-detail-img" />
          <div class="pop-detail-name">
            <span v-if="isCN">{{ info.title }}</span>
            <span v-else>{{ info.titleEn }}</span>
          </div>
          <div class="pop-detail-line">
            <div class="pop-detail-label">产品介绍：</div>
            BOPETG热收缩膜，是指在特定温度下，按照指定曲线进行收缩的双向拉伸聚酯薄膜，通常分为超高高、中等不同程度收缩、乳白色收缩膜、UV可探测收缩膜。
          </div>
          <span v-if="isCN">{{ info.detail }}</span>
          <span v-else>{{ info.detailEn }}</span>
        </div>
      </div>
    </div>
    <div class="page-pop" id="formPop">
      <div class="pop-mask" @click="closeForm"></div>
      <div class="pop-form">
        <div class="pop-title">{{ $t("useful.contact") }}</div>
        <div class="form-inner">
          <div class="form-half">
            <div class="form-label">{{ $t("useful.name") }} *</div>
            <input type="text" id="name" />
            <div class="form-msg" id="nameMsg"></div>
          </div>
          <div class="form-half">
            <div class="form-label">{{ $t("useful.phone") }} *</div>
            <input type="text" id="phone" />
            <div class="form-msg" id="phoneMsg"></div>
          </div>
          <div class="form-line">
            <div class="form-label">{{ $t("useful.email") }} *</div>
            <input type="text" id="email" />
            <div class="form-msg" id="emailMsg"></div>
          </div>
          <div class="form-line">
            <div class="form-label">{{ $t("navBar.product") }} *</div>
            <div
              class="form-radio radio-active"
              id="checkOne"
              @click="handleCheck"
            >
              <div class="form-cycle">
                <div class="form-check"></div>
              </div>
              {{ $t("navBar.white") }}
            </div>
            <div class="form-radio" id="checkTwo" @click="handleCheck">
              <div class="form-cycle">
                <div class="form-check"></div>
              </div>
              {{ $t("navBar.transparent") }}
            </div>
          </div>
          <div class="form-line">
            <div class="form-label">{{ $t("useful.require") }} *</div>
            <textarea id="remark"></textarea>
            <div class="form-msg" id="remarkMsg"></div>
          </div>
          <div class="form-btn" @click="onSub">{{ $t("useful.submit") }}</div>
        </div>
      </div>
    </div>
    <div class="page-inner">
      <div class="pro-top">
        <img :src="apiUrl + info.productImage" class="pro-top-img" />
        <div class="pro-top-info">
          <div class="pro-top-name">
            <span v-if="isCN">{{ info.title }}</span>
            <span v-else>{{ info.titleEn }}</span>
          </div>
          <!-- <span v-if="isCN">{{ info.summary }}</span>
          <span v-else>{{ info.summaryEn }}</span>
          <div class="pro-top-wrap">
            <div
              class="pro-top-wrap-box"
              v-for="(item, i) in info.tagImages"
              :key="i"
            >
              <img :src="apiUrl + item.tagImage" />
              <div class="pro-top-wrap-text">
                <span v-if="isCN">{{ info.title }}</span>
                <span v-else>{{ info.titleEn }}</span>
              </div>
            </div>
          </div> -->
          <div class="pro-top-btn" @click="popForm">
            {{ $t("useful.consult") }}
          </div>
        </div>
      </div>
      <div v-if="info.productType == 'PRODUCT'">
        <div class="pro-nav">
          <div class="pro-nav-box pro-nav-active">
            {{ $t("useful.description") }}
          </div>
          <!-- <div class="pro-nav-box">{{ $t("useful.application") }}</div> -->
        </div>
        <div class="pro-desc">
          <div class="pro-desc-title">
            <div v-if="isCN" class="pro-desc-title-cn">
              {{ $t("pro.intro") }}
            </div>
            <span v-else>{{ $t("pro.intro") }}</span>
          </div>
          <span v-if="isCN">{{ info.description }}</span>
          <span v-else>{{ info.descriptionEn }}</span>
        </div>
        <div class="pro-desc">
          <div class="pro-desc-title">
            <div v-if="isCN" class="pro-desc-title-cn">
              {{ $t("pro.apply") }}
            </div>
            <span v-else>{{ $t("pro.apply") }}</span>
          </div>
          <span v-if="isCN">{{ info.application }}</span>
          <span v-else>{{ info.applicationEn }}</span>
        </div>
        <div class="pro-desc">
          <div class="pro-desc-title">
            <div v-if="isCN" class="pro-desc-title-cn">
              {{ $t("pro.specifi") }}
            </div>
            <span v-else>{{ $t("pro.specifi") }}</span>
          </div>
          <img v-if="isCN" :src="apiUrl + info.detail" class="specifi-img" />
          <img v-else :src="apiUrl + info.detailEn" class="specifi-img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProductById, addConsult } from "@/request/Api";

export default {
  name: "proDetail",
  data() {
    return {
      isCN: true,
      id: "",
      info: {},
    };
  },
  mounted() {
    if (localStorage.getItem("lang") == "cn") {
      this.isCN = true;
    } else {
      this.isCN = false;
    }
    this.$EventBus.$on("changeLang", (res) => {
      if (res == "cn") {
        this.isCN = true;
      } else {
        this.isCN = false;
      }
    });

    document.documentElement.scrollTop = 0;

    this.id = this.$route.params.id;
    this.getData();
  },
  methods: {
    getData() {
      getProductById({
        productId: this.id,
      }).then((res) => {
        this.info = res.data;
      });
    },
    onSub() {
      if (document.getElementById("name").value == "") {
        document.getElementById("nameMsg").innerHTML =
          this.$t("useful.enter") + this.$t("useful.name");
        return;
      } else {
        document.getElementById("nameMsg").innerHTML = "";
      }
      if (document.getElementById("phone").value == "") {
        document.getElementById("phoneMsg").innerHTML =
          this.$t("useful.enter") + this.$t("useful.phone");
        return;
      } else {
        document.getElementById("phoneMsg").innerHTML = "";
      }
      if (document.getElementById("email").value == "") {
        document.getElementById("emailMsg").innerHTML =
          this.$t("useful.enter") + this.$t("useful.email");
        return;
      } else {
        document.getElementById("emailMsg").innerHTML = "";
      }
      if (document.getElementById("remark").value == "") {
        document.getElementById("remarkMsg").innerHTML =
          this.$t("useful.enter") + this.$t("useful.require");
        return;
      } else {
        document.getElementById("remarkMsg").innerHTML = "";
      }
      let radio = true;
      if (document.getElementById("checkOne").className == "form-radio") {
        radio = false;
      }
      addConsult({
        productId: this.id,
        consultName: document.getElementById("name").value,
        phone: document.getElementById("phone").value,
        email: document.getElementById("email").value,
        productType: radio ? "乳白色PETG热收缩膜" : "透明PETG热收缩膜",
        requirements: document.getElementById("remark").value,
      }).then((res) => {
        var box = document.getElementById("formPop");
        box.style.animation = "fadeOut 0.3s";
        setTimeout(function () {
          box.style.display = "none";
          box.style.animation = "fadeIn 0.3s";
        }, 250);
      });
    },
    closeForm() {
      var box = document.getElementById("formPop");
      box.style.animation = "fadeOut 0.3s";
      setTimeout(function () {
        box.style.display = "none";
        box.style.animation = "fadeIn 0.3s";
      }, 250);
    },
    popForm() {
      document.getElementById("formPop").style.display = "block";
    },
    handleCheck() {
      if (
        document.getElementById("checkOne").className ==
        "form-radio radio-active"
      ) {
        document.getElementById("checkOne").className = "form-radio";
        document.getElementById("checkTwo").className =
          "form-radio radio-active";
      } else {
        document.getElementById("checkOne").className =
          "form-radio radio-active";
        document.getElementById("checkTwo").className = "form-radio";
      }
    },
    // popDetail() {
    //   document.getElementById("detailPop").style.display = "block";
    // },
    closeDetail() {
      var box = document.getElementById("detailPop");
      box.style.animation = "fadeOut 0.3s";
      setTimeout(function () {
        box.style.display = "none";
        box.style.animation = "fadeIn 0.3s";
      }, 250);
    },
  },
};
</script>
<style lang="less" scoped>
#detailPop {
  display: none;
}

.pop-detail {
  min-width: 1082px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  background-color: #fff;
  border-radius: 6px;
  user-select: none;
}

.pop-detail-title {
  line-height: 62px;
  position: relative;
  color: #000;
  background-color: #f7f7f7;
  font-size: 20px;
  font-weight: bold;
  padding-left: 39px;
}

.pop-detail-title-cycle {
  width: 8px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  bottom: 2px;
  background: linear-gradient(0deg, #458f42, #458f42, #458f42);
  border-radius: 100%;
  margin-right: 6px;
}

.pop-detail-close {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 26px;
  top: 50%;
  transform: translateY(-50%);
  object-fit: contain;
  cursor: pointer;
  user-select: none;
}

.pop-detail-inner {
  max-height: 50vh;
  overflow-y: auto;
  padding: 53px 68px 71px;
}

.pop-detail-img {
  width: 449px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.pop-detail-name {
  text-align: center;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  margin: 15px 0 50px;
}

.pop-detail-line {
  line-height: 25px;
  color: #666;
  margin-top: 30px;
}

.pop-detail-label {
  display: inline-block;
  color: #000;
}

#formPop {
  display: none;
}

.pop-form {
  min-width: 45%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 6px;
  padding: 52px 131px 64px;
  user-select: none;
}

.pop-title {
  text-align: center;
  background: linear-gradient(
    0deg,
    #458f42 0%,
    #458f42 50.2685546875%,
    #458f42 99.21875%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  margin-bottom: 60px;
}

.form-half {
  width: calc(50% - 25px);
  display: inline-block;
  vertical-align: top;
}

.form-half:first-child {
  margin-right: 38px;
}

.form-label {
  color: #2f3036;
  font-size: 16px;
  margin-bottom: 10px;
}

.pop-form input {
  width: calc(100% - 30px);
  height: 48px;
  line-height: 48px;
  outline: none;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  font-size: 16px;
  padding: 0 15px;
}

.form-msg {
  color: red;
  margin-top: 5px;
}

.form-line {
  margin: 17px 0;
}

.form-line input {
  width: calc(100% - 38px);
}

.form-radio,
.form-cycle {
  display: inline-block;
  vertical-align: middle;
}

.form-radio {
  color: #666;
  font-size: 14px;
  margin-right: 27px;
  cursor: pointer;
}

.form-cycle {
  width: 18px;
  height: 18px;
  position: relative;
  bottom: 1px;
  border: 1px solid #e5e5e5;
  border-radius: 100%;
  margin-right: 5px;
}

.radio-active .form-cycle {
  background-color: #458f42;
  border-color: #458f42;
}

.form-check {
  width: 4px;
  height: 8px;
  position: relative;
  top: 3px;
  left: 6px;
  transform: rotate(45deg);
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}

.pop-form textarea {
  width: calc(100% - 38px);
  height: 68px;
  outline: none;
  resize: none;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  font-size: 16px;
  padding: 15px;
}

.form-btn {
  width: 217px;
  line-height: 49px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s;
  text-align: center;
  color: #fff;
  background: linear-gradient(0deg, #458f42, #458f42, #458f42);
  border-radius: 25px;
  font-size: 16px;
  margin-top: 36px;
  cursor: pointer;
}

.form-btn:hover {
  opacity: 0.8;
}

.pro-top {
  position: relative;
  background-color: #f7f7f7;
  margin: 52px 0 68px;
}

.pro-top-img,
.pro-top-info {
  display: inline-block;
  vertical-align: top;
}

.pro-top-img {
  width: 55%;
  height: 407px;
  object-fit: cover;
  // cursor: pointer;
  // user-select: none;
}

.pro-top-info {
  width: calc(45% - 55px);
  line-height: 19px;
  color: #666;
  font-size: 16px;
  padding-top: 40px;
  margin-left: 46px;
}

.pro-top-name {
  line-height: 45px;
  color: #000;
  font-size: 40px;
  margin-bottom: 21px;
}

.pro-top-wrap {
  margin-top: 50px;
}

.pro-top-wrap-box {
  width: 96px;
  line-height: 16px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  color: #000;
  background-color: #ececec;
  font-size: 14px;
  padding: 15px;
}

.pro-top-wrap-box img {
  width: 33px;
  height: 33px;
  object-fit: contain;
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 8px;
}
.pro-top-wrap-text {
  width: 90%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.pro-top-btn {
  width: 115px;
  line-height: 35px;
  position: absolute;
  bottom: 40px;
  transition: 0.3s;
  text-align: center;
  color: #fff;
  background: linear-gradient(0deg, #458f42, #458f42, #458f42);
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  user-select: none;
}

.pro-top-btn:hover {
  opacity: 0.8;
}

.pro-nav {
  background-color: #ececec;
}

.pro-nav-box {
  line-height: 52px;
  display: inline-block;
  transition: 0.3s;
  color: #666;
  font-size: 16px;
  padding: 0 42px;
  margin-right: -4px;
  cursor: pointer;
  user-select: none;
}

.pro-nav-active,
.pro-nav-box:hover {
  color: #fff;
  background: linear-gradient(0deg, #458f42, #458f42, #458f42);
}

.pro-desc {
  line-height: 21px;
  color: #666;
  font-size: 14px;
  margin: 26px 0 40px;
}

.pro-desc:last-child {
  margin-bottom: 97px;
}

.pro-desc-title {
  color: #000;
  border-bottom: 1px solid #6eb92b;
  font-size: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.pro-desc-title-cn {
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  font-weight: 500;
}
.specifi-img {
  max-width: 100%;
}
@media screen and (max-width: 600px) {
  .pop-detail {
    width: 90vw;
    min-width: unset;
  }

  .pop-detail-title {
    line-height: 48px;
    font-size: 18px;
    padding-left: 15px;
  }

  .pop-detail-close {
    width: 20px;
    height: 20px;
    right: 10px;
  }

  .pop-detail-inner {
    padding: 15px;
  }

  .pop-detail-img {
    width: 90%;
  }

  .pop-detail-name {
    margin: 10px 0 25px;
  }

  .pop-detail-line {
    margin-top: 15px;
  }

  .pop-form {
    min-width: 70%;
    padding: 15px 30px 30px;
  }

  .pop-title {
    margin-bottom: 20px;
  }

  .form-half {
    width: 100%;
    display: block;
    margin-bottom: 15px;
  }

  .pop-form input {
    height: 38px;
    line-height: 38px;
  }

  .form-radio {
    margin-bottom: 10px;
  }

  .pop-form textarea {
    height: 45px;
  }

  .form-btn {
    line-height: 40px;
    margin-top: 15px;
  }

  .pro-top {
    margin: 15px;
  }

  .pro-top-img,
  .pro-top-info {
    display: block;
  }

  .pro-top-img {
    width: 100%;
    height: 50vw;
  }

  .pro-top-info {
    width: calc(100% - 30px);
    padding: 15px 15px 20px;
    margin-left: 0;
  }

  .pro-top-name {
    line-height: 31px;
    font-size: 26px;
    margin-bottom: 11px;
  }

  .pro-top-wrap {
    margin-top: 30px;
  }

  .pro-top-wrap-box {
    width: 32%;
    padding: 15px 0;
  }
  .pro-top-wrap-text {
    width: 80%;
  }

  .pro-top-btn {
    width: 115px;
    line-height: 35px;
    position: relative;
    bottom: 0;
    margin-top: 40px;
  }

  .pro-nav-box {
    line-height: 42px;
    padding: 0 30px;
  }

  .pro-desc {
    margin: 26px 15px 40px;
  }

  .pro-desc:last-child {
    margin-bottom: 40px;
  }

  .pro-desc-title {
    font-size: 18px;
    padding-bottom: 10px;
  }

  .pro-desc-title-cn {
    font-size: 14px;
  }
}
</style>

import Vue from "vue";
import App from "./App.vue";

import VueI18n from "vue-i18n"; // 国际化
Vue.use(VueI18n);
import cnLanguage from "./utils/language/cn.js";
import enLanguage from "./utils/language/en.js";

localStorage.setItem("lang", "cn"); // 设置默认语言
let i18n = new VueI18n({
  locale: localStorage.getItem("lang"),
  messages: {
    // 语言包
    cn: cnLanguage,
    en: enLanguage,
  },
});

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

import router from "./router";

Vue.config.productionTip = false;

Vue.prototype.apiUrl = "/api";

Vue.prototype.$EventBus = new Vue(); //事件总线

new Vue({
  router,
  i18n,
  VueI18n,
  el: "#app",
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div id="homePage">
    <div id="top" class="page-all">
      <a href="#top" class="page-fixed pc-hide">
        <img src="../assets/img/arrow-up.png" />
      </a>
      <div class="page-nav">
        <div class="nav-logo">
          <div class="logo-box">
            <img src="../assets/img/logo.png" />
          </div>
        </div>
        <div class="lang-box pc-hide lang-mo" @click="moLanguage">
          <span id="langSVGmo">
            <svg
              t="1716783396111"
              class="icon lang-flag"
              viewBox="0 0 1536 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="2181"
            >
              <path
                d="M0 0h1536.090316v1024H0z"
                fill="#F40002"
                p-id="2182"
              ></path>
              <path
                d="M254.690422 104.40501l34.139354 106.93385 112.172164-0.722526-91.038278 65.569237 35.223143 106.572587-90.496383-66.472393-90.496384 66.472393 35.223143-106.572587L108.378903 210.616334l112.172164 0.722526zM477.228435 66.111131l33.958723 14.992415 24.204622-27.997884-3.61263 36.848827 34.139354 14.450521-36.126301 7.947786-3.251367 37.029458L507.75516 117.410478l-36.126301 8.309049 24.565884-27.817251zM591.568178 160.400776l24.565884 27.817252 33.778092-15.173047-18.785677 31.971777 24.927148 27.455989-36.306932-7.947787-18.424414 32.152408-3.61263-36.848827-36.126301-7.767155 33.958723-14.811783zM612.702064 308.699241l10.83789 35.584407 37.21009 0.180631-30.346093 21.314518 11.199154 35.223143-29.623567-22.217675-30.165461 21.675781 12.10231-35.042512-29.984829-22.037044 37.21009 0.541895zM471.990122 429.180455l35.223143 11.379785 21.314518-30.346093 0.180631 37.21009 35.403775 11.018522-35.223143 11.379785 0.361263 37.21009-21.856412-29.98483-35.042512 11.741048 21.67578-29.98483z"
                fill="#FCEE21"
                p-id="2183"
              ></path></svg
          ></span>
          <span id="langMo">中文</span>
          <img src="../assets/img/icon-switch.png" class="lang-arrow" />
        </div>
        <div class="nav-part">
          <div id="nav0" class="nav-box nav-active" @click="changeNav('/', 0)">
            {{ $t("navBar.index") }}
          </div>
          <div id="nav1" class="nav-box" @click="changeNav('/proList', 1)">
            {{ $t("navBar.product") }}
          </div>
          <div id="nav2" class="nav-box" @click="changeNav('/aboutPage', 2)">
            {{ $t("navBar.about") }}
          </div>
          <div id="nav3" class="nav-box" @click="changeNav('/culturePage', 3)">
            {{ $t("navBar.culture") }}
          </div>
          <div id="nav4" class="nav-box" @click="changeNav('/factoryPage', 4)">
            {{ $t("navBar.factory") }}
          </div>
          <div
            id="nav5"
            class="nav-box"
            @click="changeNav('/cooperationPage', 5)"
          >
            {{ $t("navBar.cooperation") }}
          </div>
          <div id="nav6" class="nav-box" @click="changeNav('/contactPage', 6)">
            {{ $t("navBar.contact") }}
          </div>
        </div>
        <div class="lang-box mobile-hide">
          <span id="langSVG">
            <svg
              t="1716783396111"
              class="icon lang-flag"
              viewBox="0 0 1536 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="2181"
            >
              <path
                d="M0 0h1536.090316v1024H0z"
                fill="#F40002"
                p-id="2182"
              ></path>
              <path
                d="M254.690422 104.40501l34.139354 106.93385 112.172164-0.722526-91.038278 65.569237 35.223143 106.572587-90.496383-66.472393-90.496384 66.472393 35.223143-106.572587L108.378903 210.616334l112.172164 0.722526zM477.228435 66.111131l33.958723 14.992415 24.204622-27.997884-3.61263 36.848827 34.139354 14.450521-36.126301 7.947786-3.251367 37.029458L507.75516 117.410478l-36.126301 8.309049 24.565884-27.817251zM591.568178 160.400776l24.565884 27.817252 33.778092-15.173047-18.785677 31.971777 24.927148 27.455989-36.306932-7.947787-18.424414 32.152408-3.61263-36.848827-36.126301-7.767155 33.958723-14.811783zM612.702064 308.699241l10.83789 35.584407 37.21009 0.180631-30.346093 21.314518 11.199154 35.223143-29.623567-22.217675-30.165461 21.675781 12.10231-35.042512-29.984829-22.037044 37.21009 0.541895zM471.990122 429.180455l35.223143 11.379785 21.314518-30.346093 0.180631 37.21009 35.403775 11.018522-35.223143 11.379785 0.361263 37.21009-21.856412-29.98483-35.042512 11.741048 21.67578-29.98483z"
                fill="#FCEE21"
                p-id="2183"
              ></path></svg
          ></span>
          <span id="lang">中文</span>
          <img src="../assets/img/arrow-down-green.png" class="lang-arrow" />
          <div class="lang-list">
            <div class="lang-list-top"></div>
            <div class="lang-list-box" @click="selectLanguage('en')">
              <svg
                t="1716783437582"
                class="icon lang-flag"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="3535"
              >
                <path
                  d="M23.273 141.964h977.454V793.6H23.273z"
                  fill="#083F80"
                  p-id="3536"
                ></path>
                <path
                  d="M23.273 370.036h977.454V563.2H23.273z"
                  fill="#FFFFFF"
                  p-id="3537"
                ></path>
                <path
                  d="M605.347 140.428v651.636H412.183V140.428z"
                  fill="#FFFFFF"
                  p-id="3538"
                ></path>
                <path
                  d="M1000.727 721.455V793.6H884.364L23.273 221.09v-79.126h114.036z"
                  fill="#FFFFFF"
                  p-id="3539"
                ></path>
                <path
                  d="M1000.727 214.11v-72.146H884.364L23.273 712.145V793.6h114.036z"
                  fill="#FFFFFF"
                  p-id="3540"
                ></path>
                <path
                  d="M23.273 193.164l262.982 176.872h79.127L23.272 141.964z m977.454 546.909L737.745 563.2h-79.127l342.11 230.4zM605.091 351.418v18.618h51.2l344.436-228.072h-86.109zM418.909 584.145V563.2h-51.2L23.273 793.6h86.109z m34.909-442.181h114.037V793.6H453.818z"
                  fill="#B7333C"
                  p-id="3541"
                ></path>
                <path
                  d="M20.852 524.59V410.555h979.782v114.037z"
                  fill="#B7333C"
                  p-id="3542"
                ></path>
              </svg>
              English
            </div>
            <div class="lang-list-box" @click="selectLanguage('cn')">
              <svg
                t="1716783396111"
                class="icon lang-flag"
                viewBox="0 0 1536 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2181"
              >
                <path
                  d="M0 0h1536.090316v1024H0z"
                  fill="#F40002"
                  p-id="2182"
                ></path>
                <path
                  d="M254.690422 104.40501l34.139354 106.93385 112.172164-0.722526-91.038278 65.569237 35.223143 106.572587-90.496383-66.472393-90.496384 66.472393 35.223143-106.572587L108.378903 210.616334l112.172164 0.722526zM477.228435 66.111131l33.958723 14.992415 24.204622-27.997884-3.61263 36.848827 34.139354 14.450521-36.126301 7.947786-3.251367 37.029458L507.75516 117.410478l-36.126301 8.309049 24.565884-27.817251zM591.568178 160.400776l24.565884 27.817252 33.778092-15.173047-18.785677 31.971777 24.927148 27.455989-36.306932-7.947787-18.424414 32.152408-3.61263-36.848827-36.126301-7.767155 33.958723-14.811783zM612.702064 308.699241l10.83789 35.584407 37.21009 0.180631-30.346093 21.314518 11.199154 35.223143-29.623567-22.217675-30.165461 21.675781 12.10231-35.042512-29.984829-22.037044 37.21009 0.541895zM471.990122 429.180455l35.223143 11.379785 21.314518-30.346093 0.180631 37.21009 35.403775 11.018522-35.223143 11.379785 0.361263 37.21009-21.856412-29.98483-35.042512 11.741048 21.67578-29.98483z"
                  fill="#FCEE21"
                  p-id="2183"
                ></path>
              </svg>
              中文
            </div>
          </div>
        </div>
        <div class="mobile-nav pc-hide">
          <div id="menuBtn" class="mobile-menuBtn" @click="handleMenu">
            {{ $t("navBar.menu") }}
          </div>
          <div id="menu" class="nav-part">
            <div
              id="nav0"
              class="nav-box nav-active"
              @click="changeNav('/', 0)"
            >
              {{ $t("navBar.index") }}
            </div>
            <div id="nav1" class="nav-box" @click="changeNav('/proList', 1)">
              {{ $t("navBar.product") }}
            </div>
            <div id="nav2" class="nav-box" @click="changeNav('/aboutPage', 2)">
              {{ $t("navBar.about") }}
            </div>
            <div
              id="nav3"
              class="nav-box"
              @click="changeNav('/culturePage', 3)"
            >
              {{ $t("navBar.culture") }}
            </div>
            <div
              id="nav4"
              class="nav-box"
              @click="changeNav('/factoryPage', 4)"
            >
              {{ $t("navBar.factory") }}
            </div>
            <div
              id="nav5"
              class="nav-box"
              @click="changeNav('/cooperationPage', 5)"
            >
              {{ $t("navBar.cooperation") }}
            </div>
            <div
              id="nav6"
              class="nav-box"
              @click="changeNav('/contactPage', 6)"
            >
              {{ $t("navBar.contact") }}
            </div>
          </div>
        </div>
      </div>
      <router-view></router-view>
      <div class="page-footer">
        <div class="page-inner">
          <div class="footer-left">
            <img src="../assets/img/logo-white.png" class="footer-logo" />
            GUANGDONG GREEN KING NEW MATERIAL CO.,LTD.
            <div class="footer-left-icon">
              <div @click="toPage(foot.facebookUrl)">
                <img src="../assets/img/cycle-facebook.png" />
              </div>
              <div @click="toPage(foot.twitterUrl)">
                <img src="../assets/img/cycle-twitter.png" />
              </div>
              <div @click="toPage(foot.youtubeUrl)">
                <img src="../assets/img/cycle-youtube.png" />
              </div>
              <div @click="toPage(foot.insUrl)">
                <img src="../assets/img/cycle-instagram.png" />
              </div>
            </div>
          </div>
          <div class="footer-right">
            <div class="footer-right-box mobile-hide">
              <div class="footer-right-title">{{ $t("navBar.main") }}</div>
              <div @click="$router.push('/')" class="footer-right-link">
                · {{ $t("navBar.index") }}
              </div>
              <div @click="$router.push('/proList')" class="footer-right-link">
                · {{ $t("navBar.product") }}
              </div>
              <div
                @click="$router.push('/aboutPage')"
                class="footer-right-link"
              >
                · {{ $t("navBar.about") }}
              </div>
              <div
                @click="$router.push('/culturePage')"
                class="footer-right-link"
              >
                · {{ $t("navBar.culture") }}
              </div>
              <div
                @click="$router.push('/factoryPage')"
                class="footer-right-link"
              >
                · {{ $t("navBar.factory") }}
              </div>
              <div
                @click="$router.push('/cooperationPage')"
                class="footer-right-link"
              >
                · {{ $t("navBar.cooperation") }}
              </div>
              <div
                @click="$router.push('/contactPage')"
                class="footer-right-link"
              >
                · {{ $t("navBar.contact") }}
              </div>
            </div>
            <div class="footer-right-box">
              <div class="footer-right-title mobile-hide">
                {{ $t("navBar.contact") }}
              </div>
              <div class="footer-right-text">
                <img src="../assets/img/icon-address.png" />
                <div class="footer-right-text-box">
                  <span v-if="isCN">{{ foot.address }}</span>
                  <span v-else>{{ foot.addressEn }}</span>
                </div>
              </div>
              <div class="footer-right-text">
                <img src="../assets/img/icon-user.png" />
                <div class="footer-right-text-box">
                  <span v-if="isCN">{{ foot.contactName }}</span>
                  <span v-else>{{ foot.contactNameEn }}</span>
                </div>
              </div>
              <div class="footer-right-text">
                <img src="../assets/img/icon-email.png" />
                <div class="footer-right-text-box">{{ foot.email }}</div>
              </div>
              <div class="footer-right-text">
                <img src="../assets/img/icon-phone.png" />
                <div class="footer-right-text-box">{{ foot.phone }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bot">
          <div class="page-inner">
            <div class="footer-bot-left">
              {{ $t("navBar.copyright") }} © 2024 {{ $t("navBar.gusher") }}
            </div>
            <div class="footer-bot-right">
              <a href="#" class="footer-bot-link">{{ $t("navBar.cookie") }}</a>
              <div class="footer-bot-line"></div>
              <a href="#" class="footer-bot-link">{{ $t("navBar.privacy") }}</a>
              <div class="footer-bot-line"></div>
              <a href="#" class="footer-bot-link">{{ $t("navBar.terms") }}</a>
              <div class="footer-bot-line"></div>
              <a href="#" class="footer-bot-link">{{ $t("navBar.commit") }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSystemInfo } from "@/request/Api";

export default {
  name: "homePage",
  data() {
    return {
      isCN: true,
      foot: {},
    };
  },
  mounted() {
    let lang = localStorage.getItem("lang");
    if (lang == "cn") {
      this.isCN = true;
    } else {
      this.isCN = false;
    }
    this.getData();
  },
  methods: {
    getData() {
      getSystemInfo().then((res) => {
        this.foot = res.data;
      });
    },
    selectLanguage(e) {
      this._i18n.locale = e; // 修改语言
      this.$EventBus.$emit("changeLang", e);
      if (e == "cn") {
        this.isCN = true;
        localStorage.setItem("lang", "cn");
        document.getElementById("lang").innerHTML = "中文";
        document.getElementById("langSVG").innerHTML =
          '<svg t="1716783396111" class="icon" viewBox="0 0 1536 1024" style="position:relative;top:5px;margin-right: 5px;" width="35" height="23" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2181"><path d="M0 0h1536.090316v1024H0z" fill="#F40002" p-id="2182"></path><path d="M254.690422 104.40501l34.139354 106.93385 112.172164-0.722526-91.038278 65.569237 35.223143 106.572587-90.496383-66.472393-90.496384 66.472393 35.223143-106.572587L108.378903 210.616334l112.172164 0.722526zM477.228435 66.111131l33.958723 14.992415 24.204622-27.997884-3.61263 36.848827 34.139354 14.450521-36.126301 7.947786-3.251367 37.029458L507.75516 117.410478l-36.126301 8.309049 24.565884-27.817251zM591.568178 160.400776l24.565884 27.817252 33.778092-15.173047-18.785677 31.971777 24.927148 27.455989-36.306932-7.947787-18.424414 32.152408-3.61263-36.848827-36.126301-7.767155 33.958723-14.811783zM612.702064 308.699241l10.83789 35.584407 37.21009 0.180631-30.346093 21.314518 11.199154 35.223143-29.623567-22.217675-30.165461 21.675781 12.10231-35.042512-29.984829-22.037044 37.21009 0.541895zM471.990122 429.180455l35.223143 11.379785 21.314518-30.346093 0.180631 37.21009 35.403775 11.018522-35.223143 11.379785 0.361263 37.21009-21.856412-29.98483-35.042512 11.741048 21.67578-29.98483z" fill="#FCEE21" p-id="2183"></path></svg>';
        document.getElementById("langMo").innerHTML = "中文";
        document.getElementById("langSVGmo").innerHTML =
          '<svg t="1716783396111" class="icon" viewBox="0 0 1536 1024" style="position:relative;top:5px;margin-right: 5px;" width="35" height="23" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2181"><path d="M0 0h1536.090316v1024H0z" fill="#F40002" p-id="2182"></path><path d="M254.690422 104.40501l34.139354 106.93385 112.172164-0.722526-91.038278 65.569237 35.223143 106.572587-90.496383-66.472393-90.496384 66.472393 35.223143-106.572587L108.378903 210.616334l112.172164 0.722526zM477.228435 66.111131l33.958723 14.992415 24.204622-27.997884-3.61263 36.848827 34.139354 14.450521-36.126301 7.947786-3.251367 37.029458L507.75516 117.410478l-36.126301 8.309049 24.565884-27.817251zM591.568178 160.400776l24.565884 27.817252 33.778092-15.173047-18.785677 31.971777 24.927148 27.455989-36.306932-7.947787-18.424414 32.152408-3.61263-36.848827-36.126301-7.767155 33.958723-14.811783zM612.702064 308.699241l10.83789 35.584407 37.21009 0.180631-30.346093 21.314518 11.199154 35.223143-29.623567-22.217675-30.165461 21.675781 12.10231-35.042512-29.984829-22.037044 37.21009 0.541895zM471.990122 429.180455l35.223143 11.379785 21.314518-30.346093 0.180631 37.21009 35.403775 11.018522-35.223143 11.379785 0.361263 37.21009-21.856412-29.98483-35.042512 11.741048 21.67578-29.98483z" fill="#FCEE21" p-id="2183"></path></svg>';
      } else {
        this.isCN = false;
        localStorage.setItem("lang", "en");
        document.getElementById("lang").innerHTML = "English";
        document.getElementById("langSVG").innerHTML =
          '<svg t="1716790676642" class="icon" viewBox="0 0 1536 1024" style="position:relative;top:5px;margin-right: 5px;" width="35" height="23" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2972"><path d="M0 0h1536v1024H0V0z" fill="#00458F" p-id="2973"></path><path d="M1536 358.4H921.6V0h-307.2v358.4H0v307.2h614.4V1024h307.2V665.6H1536v-307.2z" fill="#FFFFFF" p-id="2974"></path><path d="M1536 905.6L176 0H0v118.4L1360 1024H1536v-118.4z" fill="#FFFFFF" p-id="2975"></path><path d="M0 905.6L1360 0H1536v118.4L176 1024H0v-118.4z" fill="#FFFFFF" p-id="2976"></path><path d="M1536 422.4H857.6V0h-179.2v422.4H0v179.2h678.4V1024h179.2V601.6H1536v-179.2z" fill="#CC3440" p-id="2977"></path><path d="M1536 1020.8v-80l-412.8-275.2h-121.6L1536 1020.8zM1536 0h-121.6L921.6 326.4v28.8h76.8L1536 0zM534.4 665.6L0 1020.8v3.2h115.2l496-329.6v-28.8h-76.8zM0 0v80l416 278.4h121.6L0 0z" fill="#CC3440" p-id="2978"></path></svg>';
        document.getElementById("langMo").innerHTML = "English";
        document.getElementById("langSVGmo").innerHTML =
          '<svg t="1716790676642" class="icon" viewBox="0 0 1536 1024" style="position:relative;top:5px;margin-right: 5px;" width="35" height="23" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2972"><path d="M0 0h1536v1024H0V0z" fill="#00458F" p-id="2973"></path><path d="M1536 358.4H921.6V0h-307.2v358.4H0v307.2h614.4V1024h307.2V665.6H1536v-307.2z" fill="#FFFFFF" p-id="2974"></path><path d="M1536 905.6L176 0H0v118.4L1360 1024H1536v-118.4z" fill="#FFFFFF" p-id="2975"></path><path d="M0 905.6L1360 0H1536v118.4L176 1024H0v-118.4z" fill="#FFFFFF" p-id="2976"></path><path d="M1536 422.4H857.6V0h-179.2v422.4H0v179.2h678.4V1024h179.2V601.6H1536v-179.2z" fill="#CC3440" p-id="2977"></path><path d="M1536 1020.8v-80l-412.8-275.2h-121.6L1536 1020.8zM1536 0h-121.6L921.6 326.4v28.8h76.8L1536 0zM534.4 665.6L0 1020.8v3.2h115.2l496-329.6v-28.8h-76.8zM0 0v80l416 278.4h121.6L0 0z" fill="#CC3440" p-id="2978"></path></svg>';
      }
    },
    moLanguage() {
      this.isCN = !this.isCN;
      if (this.isCN) {
        this._i18n.locale = "cn"; // 修改语言
        this.$EventBus.$emit("changeLang", "cn");
        localStorage.setItem("lang", "cn");
        document.getElementById("lang").innerHTML = "中文";
        document.getElementById("langSVG").innerHTML =
          '<svg t="1716783396111" class="icon" viewBox="0 0 1536 1024" style="position:relative;top:5px;margin-right: 5px;" width="35" height="23" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2181"><path d="M0 0h1536.090316v1024H0z" fill="#F40002" p-id="2182"></path><path d="M254.690422 104.40501l34.139354 106.93385 112.172164-0.722526-91.038278 65.569237 35.223143 106.572587-90.496383-66.472393-90.496384 66.472393 35.223143-106.572587L108.378903 210.616334l112.172164 0.722526zM477.228435 66.111131l33.958723 14.992415 24.204622-27.997884-3.61263 36.848827 34.139354 14.450521-36.126301 7.947786-3.251367 37.029458L507.75516 117.410478l-36.126301 8.309049 24.565884-27.817251zM591.568178 160.400776l24.565884 27.817252 33.778092-15.173047-18.785677 31.971777 24.927148 27.455989-36.306932-7.947787-18.424414 32.152408-3.61263-36.848827-36.126301-7.767155 33.958723-14.811783zM612.702064 308.699241l10.83789 35.584407 37.21009 0.180631-30.346093 21.314518 11.199154 35.223143-29.623567-22.217675-30.165461 21.675781 12.10231-35.042512-29.984829-22.037044 37.21009 0.541895zM471.990122 429.180455l35.223143 11.379785 21.314518-30.346093 0.180631 37.21009 35.403775 11.018522-35.223143 11.379785 0.361263 37.21009-21.856412-29.98483-35.042512 11.741048 21.67578-29.98483z" fill="#FCEE21" p-id="2183"></path></svg>';
        document.getElementById("langMo").innerHTML = "中文";
        document.getElementById("langSVGmo").innerHTML =
          '<svg t="1716783396111" class="icon" viewBox="0 0 1536 1024" style="position:relative;top:5px;margin-right: 5px;" width="35" height="23" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2181"><path d="M0 0h1536.090316v1024H0z" fill="#F40002" p-id="2182"></path><path d="M254.690422 104.40501l34.139354 106.93385 112.172164-0.722526-91.038278 65.569237 35.223143 106.572587-90.496383-66.472393-90.496384 66.472393 35.223143-106.572587L108.378903 210.616334l112.172164 0.722526zM477.228435 66.111131l33.958723 14.992415 24.204622-27.997884-3.61263 36.848827 34.139354 14.450521-36.126301 7.947786-3.251367 37.029458L507.75516 117.410478l-36.126301 8.309049 24.565884-27.817251zM591.568178 160.400776l24.565884 27.817252 33.778092-15.173047-18.785677 31.971777 24.927148 27.455989-36.306932-7.947787-18.424414 32.152408-3.61263-36.848827-36.126301-7.767155 33.958723-14.811783zM612.702064 308.699241l10.83789 35.584407 37.21009 0.180631-30.346093 21.314518 11.199154 35.223143-29.623567-22.217675-30.165461 21.675781 12.10231-35.042512-29.984829-22.037044 37.21009 0.541895zM471.990122 429.180455l35.223143 11.379785 21.314518-30.346093 0.180631 37.21009 35.403775 11.018522-35.223143 11.379785 0.361263 37.21009-21.856412-29.98483-35.042512 11.741048 21.67578-29.98483z" fill="#FCEE21" p-id="2183"></path></svg>';
      } else {
        this._i18n.locale = "en"; // 修改语言
        this.$EventBus.$emit("changeLang", "en");
        localStorage.setItem("lang", "en");
        document.getElementById("lang").innerHTML = "English";
        document.getElementById("langSVG").innerHTML =
          '<svg t="1716790676642" class="icon" viewBox="0 0 1536 1024" style="position:relative;top:5px;margin-right: 5px;" width="35" height="23" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2972"><path d="M0 0h1536v1024H0V0z" fill="#00458F" p-id="2973"></path><path d="M1536 358.4H921.6V0h-307.2v358.4H0v307.2h614.4V1024h307.2V665.6H1536v-307.2z" fill="#FFFFFF" p-id="2974"></path><path d="M1536 905.6L176 0H0v118.4L1360 1024H1536v-118.4z" fill="#FFFFFF" p-id="2975"></path><path d="M0 905.6L1360 0H1536v118.4L176 1024H0v-118.4z" fill="#FFFFFF" p-id="2976"></path><path d="M1536 422.4H857.6V0h-179.2v422.4H0v179.2h678.4V1024h179.2V601.6H1536v-179.2z" fill="#CC3440" p-id="2977"></path><path d="M1536 1020.8v-80l-412.8-275.2h-121.6L1536 1020.8zM1536 0h-121.6L921.6 326.4v28.8h76.8L1536 0zM534.4 665.6L0 1020.8v3.2h115.2l496-329.6v-28.8h-76.8zM0 0v80l416 278.4h121.6L0 0z" fill="#CC3440" p-id="2978"></path></svg>';
        document.getElementById("langMo").innerHTML = "English";
        document.getElementById("langSVGmo").innerHTML =
          '<svg t="1716790676642" class="icon" viewBox="0 0 1536 1024" style="position:relative;top:5px;margin-right: 5px;" width="35" height="23" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2972"><path d="M0 0h1536v1024H0V0z" fill="#00458F" p-id="2973"></path><path d="M1536 358.4H921.6V0h-307.2v358.4H0v307.2h614.4V1024h307.2V665.6H1536v-307.2z" fill="#FFFFFF" p-id="2974"></path><path d="M1536 905.6L176 0H0v118.4L1360 1024H1536v-118.4z" fill="#FFFFFF" p-id="2975"></path><path d="M0 905.6L1360 0H1536v118.4L176 1024H0v-118.4z" fill="#FFFFFF" p-id="2976"></path><path d="M1536 422.4H857.6V0h-179.2v422.4H0v179.2h678.4V1024h179.2V601.6H1536v-179.2z" fill="#CC3440" p-id="2977"></path><path d="M1536 1020.8v-80l-412.8-275.2h-121.6L1536 1020.8zM1536 0h-121.6L921.6 326.4v28.8h76.8L1536 0zM534.4 665.6L0 1020.8v3.2h115.2l496-329.6v-28.8h-76.8zM0 0v80l416 278.4h121.6L0 0z" fill="#CC3440" p-id="2978"></path></svg>';
      }
    },
    handleMenu() {
      if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
        if (document.getElementById("menuBtn").className == "mobile-menuBtn") {
          document.getElementById("menuBtn").className =
            "mobile-menuBtn active";
          document.getElementById("menu").style.display = "block";
        } else {
          document.getElementById("menuBtn").className = "mobile-menuBtn";
          document.getElementById("menu").style.display = "none";
        }
      }
    },
    changeNav(url, i) {
      this.$router.push(url);
      for (var j = 0; j < 6; j++) {
        if (j == i) {
          document.getElementById("nav" + j).className = "nav-box nav-active";
        } else {
          document.getElementById("nav" + j).className = "nav-box";
        }
      }
      this.handleMenu();
    },
    toPage(url) {
      if (url.indexOf("http") != -1) {
        window.location.href = url;
      } else {
        window.location.href = "https:/" + "/" + url;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.page-nav {
  position: relative;
  display: flex;
  align-items: flex-end;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px rgba(115, 124, 120, 0.4);
  padding: 5px 0;
}

.nav-logo {
  margin-left: 40px;
  user-select: none;
}

.logo-box img {
  display: inline-block;
  vertical-align: top;
}

.logo-box img {
  width: 130px;
  transition: 0.3s;
  cursor: pointer;
}

.logo-box img:hover {
  opacity: 0.6;
}

.lang-box {
  min-width: 120px;
  position: absolute;
  right: 0;
  bottom: 26px;
  color: #000;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
}
#langSVG,
#langSVGmo {
  position: relative;
}
.lang-flag,
.lang-arrow {
  display: inline-block;
  vertical-align: middle;
}

.lang-flag {
  width: 27px;
  margin-right: 5px;
}

.lang-arrow {
  width: 9px;
  margin-left: 10px;
}

.lang-box:hover .lang-list {
  display: block;
}

.lang-list {
  display: none;
  position: absolute;
  top: 21px;
  padding: 10px 0;
}

.lang-list-top {
  width: 6px;
  height: 6px;
  position: absolute;
  top: 6px;
  left: 60px;
  transform: rotate(45deg);
  background-color: #fff;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

.lang-list-box {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 8px 10px;
}
.lang-list-box:last-child {
  border-top: 0;
}

.mobile-menuBtn {
  display: none;
}

.nav-part {
  width: calc(100% - 270px);
  height: 60px;
  background-color: #fff;
  margin-left: 30px;
  user-select: none;
}

.nav-box {
  line-height: 60px;
  display: inline-block;
  text-align: center;
  padding: 0 20px;
}

.nav-box {
  text-decoration: none;
  color: #30323e;
  font-size: 18px;
  transition: 0.3s;
  cursor: pointer;
  user-select: none;
}

.page-footer {
  color: #fff;
  background: linear-gradient(0deg, #458f42, #458f42, #458f42);
  font-size: 16px;
  padding-top: 40px;
  user-select: none;
}

.footer-left {
  width: 25%;
  display: inline-block;
  vertical-align: top;
  color: #ffffffcc;
  font-size: 12px;
  padding-left: 15px;
}

.footer-logo {
  width: 293px;
  margin-bottom: 3px;
}

.footer-left-icon {
  margin-top: 79px;
}

.footer-left-icon div {
  display: inline-block;
  transition: 0.3s;
  margin-right: 18px;
  cursor: pointer;
  user-select: none;
}

.footer-left-icon div:hover {
  opacity: 0.8;
}

.footer-right {
  width: 65%;
  display: inline-block;
  vertical-align: top;
  margin-left: 100px;
}

.footer-right-box {
  width: 69%;
  display: inline-block;
  vertical-align: top;
}

.footer-right-box:first-child {
  width: 30%;
}

.footer-right-title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 30px;
}

.footer-right-link {
  line-height: 25px;
  display: block;
  text-decoration: none;
  transition: 0.3s;
  color: #fff;
  font-size: 16px;
  opacity: 0.8;
  cursor: pointer;
  user-select: none;
}

.footer-right-link:hover {
  opacity: 1;
}

.footer-right-text {
  line-height: 25px;
  color: #fff;
  font-size: 16px;
  opacity: 0.8;
}

.footer-right-text img {
  width: 15px;
  height: 17px;
  object-fit: contain;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin-right: 6px;
  opacity: 0.5;
}

.footer-right-text-box {
  width: calc(100% - 35px);
  display: inline-block;
  vertical-align: middle;
}

.footer-right-text:nth-child(2) img,
.footer-right-text:nth-child(2) .footer-right-text-box {
  vertical-align: top;
  bottom: 0;
  top: 4px;
}

.footer-bot {
  color: #ffffffcc;
  border-top: 1px solid #ffffff7f;
  padding: 26px 0 56px;
  margin-top: 70px;
}

.footer-bot-left,
.footer-bot-right,
.footer-bot-link,
.footer-bot-line {
  display: inline-block;
  vertical-align: middle;
}

.footer-bot-left {
  width: 49%;
  padding-left: 15px;
}

.footer-bot-right {
  width: 49%;
  text-align: right;
}

.footer-bot-link {
  text-decoration: none;
  transition: 0.3s;
  color: #ffffff7f;
}

.footer-bot-line {
  width: 1px;
  height: 16px;
  position: relative;
  top: 1px;
  background-color: #ffffff7f;
  margin: 0 3px;
}

.footer-bot-link:hover {
  color: #fff;
}
@media screen and (max-width: 600px) {
  .page-fixed {
    width: 12vw;
    height: 12vw;
    position: fixed;
    bottom: 5vw;
    right: 5vw;
    z-index: 1;
    background-color: #fff;
    box-shadow: 1px 2px 29px 0px rgba(0, 0, 0, 0.31);
    border-radius: 100%;
    user-select: none;
  }

  .page-fixed img {
    width: 60%;
    height: 60%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .page-nav .page-inner {
    display: block;
  }

  .page-nav {
    display: block;
    position: relative;
    padding: 0;
  }
  .nav-logo {
    display: inline-block !important;
    vertical-align: middle;
    margin-left: 0;
  }

  .logo-box img {
    width: unset;
    height: 80px;
    padding-left: 3px;
  }

  .mobile-nav {
    min-height: 40px;
    line-height: 40px;
    position: relative;
    background: linear-gradient(0deg, #458f42, #458f42, #458f42);
  }
  .lang-mo {
    display: inline-block !important;
    text-align: right;
  }
  .lang-box {
    display: inline-block;
    position: relative;
    right: calc(245px - 100vw);
    top: unset;
    bottom: unset;
    transform: unset;
    font-size: 14px;
    padding-left: 15px;
  }

  .lang-flag {
    width: 27px;
    position: relative;
    bottom: 2px;
    margin-right: 5px;
  }

  .lang-arrow {
    width: unset;
    height: 12px;
    position: relative;
    bottom: 2px;
    margin-left: 5px;
  }

  .mobile-menuBtn {
    display: block;
    position: absolute;
    right: 10px;
    top: 1px;
    color: #fff;
    user-select: none;
  }

  .nav-part {
    width: 100%;
    height: unset;
    display: none;
    position: relative;
    top: 40px;
    box-shadow: 0px 0px 6px 0px rgba(115, 124, 120, 0.4);
    padding: 11px 0;
    margin-left: 0;
    margin-bottom: 40px;
  }

  .nav-box {
    width: 100%;
    line-height: 40px;
    display: block;
    padding: 0;
  }

  .nav-box a {
    font-size: 18px;
  }

  .page-footer {
    padding-top: 30px;
  }

  .footer-left {
    width: calc(100% - 60px);
    display: block;
    padding: 0 30px;
  }

  .footer-logo {
    width: 66%;
    display: block;
    margin-bottom: 10px;
  }

  .footer-left-icon {
    margin-top: 15px;
  }

  .footer-left-icon div {
    margin-right: 8px;
  }

  .footer-left-icon div img {
    width: 25px;
    height: 25px;
    object-fit: contain;
  }

  .footer-right {
    width: calc(100% - 60px);
    display: block;
    padding: 0 30px;
    margin-left: 0;
  }

  .footer-right-box {
    width: 100%;
    display: block;
    margin-top: 40px;
  }

  .footer-right-text {
    font-size: 14px;
  }

  .footer-bot {
    font-size: 14px;
    padding: 20px 0 25px;
    margin-top: 20px;
  }

  .footer-bot-left,
  .footer-bot-right {
    display: block;
  }

  .footer-bot-left {
    width: calc(100% - 60px);
    padding: 0 30px;
  }

  .footer-bot-right {
    width: calc(100% - 60px);
    text-align: left;
    padding: 0 30px;
    margin-top: 10px;
  }

  .footer-bot-link {
    line-height: 24px;
  }

  .footer-bot-line {
    height: 14px;
  }
}
</style>

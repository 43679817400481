<template>
  <div id="factoryPage">
    <div class="factory-white-one">
      <div class="page-inner">
        <div>
          <div class="factory-box" v-for="(item, i) in list" :key="i">
            <img :src="apiUrl + item.image" />
            <div class="factory-mask">
              <div class="factory-mask-inner">
                <img src="../assets/img/icon-img.png" />
                <span v-if="isCN">{{ item.title }}</span>
                <span v-else>{{ item.titleEn }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="factory-page">
          {{ $t("useful.total") }} {{ totalNum }} {{ $t("useful.tiao") }}
          {{ nowPage }} / {{ totalPage }}
          {{ $t("useful.page") }}
          <div class="factory-page-box">
            <img src="../assets/img/icon-arrow-left.png" @click="prePage" />
            <div class="factory-page-text" @click="turnPage(1)">
              {{ $t("useful.firstPage") }}
            </div>
            <div
              v-for="(item, i) in pageList"
              :key="i"
              :class="[
                nowPage == i + 1
                  ? 'factory-page-btn factory-page-active'
                  : 'factory-page-btn',
              ]"
              @click="turnPage(i + 1)"
            >
              {{ i + 1 }}
            </div>
            <div class="factory-page-text" @click="turnPage(totalPage)">
              {{ $t("useful.lastPage") }}
            </div>
            <img src="../assets/img/icon-arrow-right.png" @click="nextPage" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
        
  <script>
import { getArticleListByType } from "@/request/Api";

export default {
  name: "factoryPage",
  data() {
    return {
      isCN: true,
      nowPage: 1,
      totalPage: 1,
      totalNum: 0,
      list: [],
      pageList: [],
    };
  },
  mounted() {
    if (localStorage.getItem("lang") == "cn") {
      this.isCN = true;
    } else {
      this.isCN = false;
    }
    this.$EventBus.$on("changeLang", (res) => {
      if (res == "cn") {
        this.isCN = true;
      } else {
        this.isCN = false;
      }
    });

    this.getList();
  },
  methods: {
    getList() {
      getArticleListByType({
        articleType: "FACTORY",
        pageNum: this.nowPage,
        pageSize: 6,
      }).then((res) => {
        this.list = res.rows;
        this.totalNum = res.total;
        this.totalPage = Math.ceil(res.total / 6);
        this.pageList = [];
        for (let i = 0; i < this.totalPage; i++) {
          this.pageList.push(1);
        }
      });
    },
    prePage() {
      if (this.nowPage > 1) {
        this.nowPage--;
        this.getList();
      }
    },
    nextPage() {
      if (this.nowPage < this.totalPage) {
        this.nowPage++;
        this.getList();
      }
    },
    turnPage(page) {
      this.nowPage = page;
      this.getList();
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>
<style lang="less" scoped>
.factory-white-one {
  padding-top: 72px;
  user-select: none;
}

.factory-box {
  width: calc(50% - 66px);
  height: 18.23vw;
  display: inline-block;
  position: relative;
  background-color: #f7f7f7;
  padding: 19px;
  margin-bottom: 30px;
}

.factory-box:nth-child(2n) {
  margin-left: 44px;
}

.factory-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.factory-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s;
  background-color: #458f42;
  opacity: 0;
}

.factory-box:hover .factory-mask {
  opacity: 0.8;
}

.factory-mask-inner {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  color: #fff;
  font-size: 24px;
}

.factory-mask-inner img {
  width: 42px;
  height: 42px;
  object-fit: contain;
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 20px;
}

.factory-page {
  text-align: center;
  color: #000;
  font-size: 18px;
  margin: 16px 0 48px;
}

.factory-page-box,
.factory-page img,
.factory-page-text,
.factory-page-btn {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
}

.factory-page img {
  height: 14px;
  transition: 0.3s;
}

.factory-page img:hover {
  transform: scale(1.3);
}

.factory-page img:first-child {
  margin-left: 60px;
}

.factory-page-text {
  transition: 0.3s;
  color: #666;
  margin: 0 15px;
}

.factory-page-text:hover {
  color: #000;
}

.factory-page-btn {
  width: 59px;
  height: 59px;
  line-height: 59px;
  transition: 0.3s;
  border-radius: 100%;
  background-color: #f7f7f7;
  margin: 0 18px;
}

.factory-page-btn:hover,
.factory-page-active {
  color: #fff;
  background: linear-gradient(0deg, #458f42, #458f42, #458f42);
}

@media screen and (max-width: 600px) {
  .factory-white-one {
    padding-top: 15px;
  }

  .factory-box {
    width: 90%;
    height: 55vw;
    display: block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    margin-bottom: 15px;
  }

  .factory-box:nth-child(2n) {
    margin-left: 0;
  }

  .factory-page {
    font-size: 16px;
    margin: 0 0 15px;
  }

  .factory-page-box {
    display: block;
    padding: 0 15px;
    margin-top: 15px;
  }

  .factory-page img:first-child {
    margin-left: 0;
  }

  .factory-page-text {
    margin: 0 5px;
  }

  .factory-page-btn {
    width: 36px;
    height: 36px;
    line-height: 36px;
    margin: 0 5px;
  }
}
</style>
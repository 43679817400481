<template>
  <div id="contactPage">
    <div class="contact-bg-one">
      <div class="page-inner">
        <div class="contact-bg-one-inner">
          <div class="contact-bg-one-inner-title">
            {{ $t("navBar.contact") }}
          </div>
          <p>{{ $t("navBar.custom") }}</p>
        </div>
      </div>
    </div>
    <div class="contact-gray-one">
      <div class="page-inner">
        <div class="contact-gray-one-box">
          <img src="../assets/img/icon-userinfo.png" />
          <div class="contact-gray-one-name">{{ $t("useful.mobile") }}</div>
          {{ info.phone }}
          <span v-if="isCN">({{ info.contactName }})</span>
          <span v-else>({{ info.contactNameEn }})</span>
        </div>
        <div class="contact-gray-one-box">
          <img src="../assets/img/icon-email-orange.png" />
          <div class="contact-gray-one-name">{{ $t("useful.email") }}</div>
          {{ info.email }}
        </div>
        <div class="contact-gray-one-box">
          <img src="../assets/img/icon-address-orange.png" />
          <div class="contact-gray-one-name">{{ $t("useful.address") }}</div>
          <span v-if="isCN">{{ info.address }}</span>
          <span v-else>{{ info.addressEn }}</span>
        </div>
        <div class="contact-gray-one-box">
          <img src="../assets/img/icon-wechat.png" />
          <div class="contact-gray-one-name">{{ $t("useful.code") }}</div>
          <div class="contact-gray-one-btn" id="openCode" @click="openCode">
            {{ $t("useful.scan") }}
          </div>
          <div class="contact-gray-one-btn" id="closeCode" @click="closeCode">
            {{ $t("useful.scan") }}
          </div>
        </div>
      </div>
    </div>
    <div class="contact-gray-one-code" id="codePop">
      <div class="pop-mask" @click="closeCode"></div>
      <img :src="apiUrl + info.ewmImage" />
    </div>
  </div>
</template>

<script>
import { getSystemInfo } from "@/request/Api";

export default {
  name: "contactPage",
  data() {
    return {
      isCN: true,
      info: {},
    };
  },
  mounted() {
    if (localStorage.getItem("lang") == "cn") {
      this.isCN = true;
    } else {
      this.isCN = false;
    }
    this.$EventBus.$on("changeLang", (res) => {
      if (res == "cn") {
        this.isCN = true;
      } else {
        this.isCN = false;
      }
    });

    this.getData();
  },
  methods: {
    getData() {
      getSystemInfo().then((res) => {
        this.info = res.data;
      });
    },
    openCode() {
      document.getElementById("openCode").style.display = "none";
      document.getElementById("closeCode").style.display = "block";
      var box = document.getElementById("codePop");
      box.style.display = "block";
      box.style.animation = "opacityIn 0.5s";
    },
    closeCode() {
      var box = document.getElementById("codePop");
      box.style.animation = "opacityOut 0.5s";
      setTimeout(function () {
        box.style.display = "none";
        document.getElementById("openCode").style.display = "block";
        document.getElementById("closeCode").style.display = "none";
      }, 450);
    },
  },
};
</script>
<style lang="less" scoped>
.contact-bg-one .page-inner {
  display: flex;
}

.contact-bg-one {
  background: url(../assets/img/contact-banner.png) no-repeat;
  background-size: cover;
}

.contact-bg-one-inner {
  width: 100%;
  text-align: center;
  color: #fff;
  margin: 211px 0 179px;
}

.contact-bg-one-inner-title {
  position: relative;
  animation: fadeIn 2s;
  font-size: 48px;
}

.contact-bg-one-inner p {
  font-size: 18px;
  font-weight: bold;
  margin-top: 12px;
}

.contact-gray-one {
  position: relative;
  z-index: 1;
  background-color: #f8f8f8;
  padding: 90px 0 29px;
}

.contact-gray-one .page-inner {
  display: flex;
  flex-wrap: wrap;
}

.contact-gray-one-box {
  width: calc(33% - 90px);
  text-align: center;
  transition: 0.3s;
  color: #666;
  background-color: #fff;
  border-radius: 10px;
  font-size: 18px;
  padding: 51px 38px 64px;
  margin-bottom: 43px;
  user-select: none;
}

.contact-gray-one-box:nth-child(3n-1) {
  margin: 0 21px 43px;
}

.contact-gray-one-box:hover {
  box-shadow: 1px 2px 29px 0px rgba(0, 0, 0, 0.15);
}

.contact-gray-one-box img {
  width: 36px;
  height: 35px;
  object-fit: contain;
}

.contact-gray-one-box:first-child img {
  height: 34px;
}

.contact-gray-one-box:nth-child(2) img {
  width: 39px;
  height: 31px;
}

.contact-gray-one-box:nth-child(3) img {
  width: 30px;
}

.contact-gray-one-box:nth-child(4) img {
  width: 43px;
}

.contact-gray-one-name {
  color: #000;
  font-weight: bold;
  margin: 15px 0 10px;
}

.contact-gray-one-box:first-child .contact-gray-one-name {
  margin-top: 16px;
}

.contact-gray-one-box:nth-child(2) .contact-gray-one-name {
  margin-top: 19px;
}

.contact-gray-one-btn {
  text-decoration: underline;
  cursor: pointer;
}

#closeCode {
  display: none;
}

.contact-gray-one-code {
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
}

.pop-mask {
  width: 100%;
  height: 100%;
  background-color: #0000007f;
}

.contact-gray-one-code img {
  width: 15vw !important;
  height: 15vw;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 600px) {
  .contact-bg-one .page-inner {
    display: block;
  }

  .contact-bg-one {
    padding: 30px 15px 40px;
  }

  .contact-bg-one-inner {
    margin: 0;
  }

  .contact-bg-one-inner-title {
    font-size: 33px;
  }

  .contact-bg-one-inner p {
    font-size: 14px;
  }

  .contact-gray-one {
    padding: 30px 15px 15px;
  }

  .contact-gray-one .page-inner {
    display: block;
  }

  .contact-gray-one-box {
    width: calc(100% - 76px);
    padding: 30px 38px 40px;
    margin-bottom: 15px;
  }

  .contact-gray-one-box:nth-child(3n-1) {
    margin: 0 0 15px;
  }

  .contact-gray-one-code img {
    width: 50vw !important;
    height: 50vw;
  }
}
</style>

<template>
  <div id="indexPage">
    <div
      class="page-bg-one"
      :style="{ 'background-image': 'url(' + apiUrl + banner.adImage + ')' }"
    >
      <div class="page-inner">
        <div class="bg-one-inner">
          <div class="bg-one-inner-title">
            <span v-if="isCN">{{ banner.title }}</span>
            <span v-else>{{ banner.titleEn }}</span>
            <div>
              <span v-if="isCN">{{ banner.summary }}</span>
              <span v-else>{{ banner.summaryEn }}</span>
            </div>
            <div class="bg-one-desc pc-hide">
              <span v-if="isCN">{{ banner.mobileSummary }}</span>
              <span v-else>{{ banner.mobileSummaryEn }}</span>
            </div>
          </div>
          <p class="mobile-hide">
            <span v-if="isCN">{{ banner.detail }}</span>
            <span v-else>{{ banner.detailEn }}</span>
          </p>
          <div class="bg-one-btn">{{ $t("useful.read") }}</div>
        </div>
      </div>
    </div>
    <div class="page-white-one">
      <div class="page-inner">
        <div class="white-one-line" v-for="(item, i) in list" :key="i">
          <img
            v-if="item.isLeft"
            :src="apiUrl + item.image"
            class="white-one-img mobile-hide"
          />
          <div
            class="white-one-box"
            :style="{
              'background-image': 'url(' + apiUrl + item.bgImage + ')',
            }"
          >
            <div class="white-one-title">
              <span v-if="isCN">{{ item.title }}</span>
              <div v-else class="white-one-en">{{ item.titleEn }}</div>
            </div>
            <div class="white-one-box-inner">
              <div class="white-one-box-text">
                <span v-if="isCN">{{ item.summary }}</span>
                <span v-else>{{ item.summaryEn }}</span>
              </div>
              <div
                class="white-one-btn"
                @click="$router.push('/detailPage/' + item.articleId)"
              >
                {{ $t("useful.read") }}
              </div>
            </div>
          </div>
          <img
            v-if="!item.isLeft"
            :src="apiUrl + item.image"
            class="white-one-img"
          />
          <img
            v-else
            :src="apiUrl + item.image"
            class="white-one-img pc-hide"
          />
        </div>
      </div>
    </div>
    <div class="page-white-two">
      <div class="page-inner">
        <div class="white-two-bot">
          <img :src="apiUrl + foot.adImage" />
          <div class="white-two-bot-inner">
            <div class="white-two-bot-title">{{ $t("navBar.contact") }}</div>
            {{ $t("navBar.custom") }}
            <div
              @click="$router.push('/contactPage')"
              class="white-two-bot-btn"
            >
              {{ $t("useful.read") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-gray-one"></div>
  </div>
</template>

<script>
import { getAdInfoByType, getArticleListByType } from "@/request/Api";

export default {
  name: "indexPage",
  data() {
    return {
      isCN: true,
      banner: {},
      foot: {},
      list: [],
    };
  },
  mounted() {
    if (localStorage.getItem("lang") == "cn") {
      this.isCN = true;
    } else {
      this.isCN = false;
    }
    this.$EventBus.$on("changeLang", (res) => {
      if (res == "cn") {
        this.isCN = true;
      } else {
        this.isCN = false;
      }
    });

    this.getData();
  },
  methods: {
    getData() {
      getAdInfoByType({
        adType: "INDEX",
      }).then((res) => {
        this.banner = res.data;
      });
      getAdInfoByType({
        adType: "FOOT",
      }).then((res) => {
        this.foot = res.data;
      });
      getArticleListByType({
        articleType: "INDEX",
      }).then((res) => {
        for (let i = 0; i < res.rows.length; i++) {
          if ((i + 1) % 2 == 0) {
            this.list.push({
              title: res.rows[i].title,
              titleEn: res.rows[i].titleEn,
              summary: res.rows[i].summary,
              summaryEn: res.rows[i].summaryEn,
              image: res.rows[i].image,
              bgImage: res.rows[i].bgImage,
              articleId: res.rows[i].articleId,
              isLeft: true,
            });
          } else {
            this.list.push({
              title: res.rows[i].title,
              titleEn: res.rows[i].titleEn,
              summary: res.rows[i].summary,
              summaryEn: res.rows[i].summaryEn,
              image: res.rows[i].image,
              bgImage: res.rows[i].bgImage,
              articleId: res.rows[i].articleId,
              isLeft: false,
            });
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.page-bg-one {
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-one-inner {
  width: 49%;
  color: #fff;
  font-size: 16px;
  padding-left: 15px;
  padding: 170px 0 130px;
}

.bg-one-inner-title {
  position: relative;
  animation: fadeIn 2s;
  font-size: 63px;
  font-weight: bold;
}

.bg-one-inner p {
  line-height: 31px;
  margin-top: 20px;
}

.bg-one-btn {
  width: 155px;
  line-height: 49px;
  text-align: center;
  transition: 0.3s;
  background: linear-gradient(0deg, #458f42, #458f42, #458f42);
  border-radius: 25px;
  margin-top: 178px;
  cursor: pointer;
  user-select: none;
}

.bg-one-btn:hover {
  opacity: 0.8;
}

.page-white-one {
  background-color: #fff;
  padding-top: 80px;
}

.white-one-line {
  width: 100%;
  height: 356px;
  position: relative;
  margin-bottom: 79px;
}

.white-one-line:last-child {
  margin-bottom: 0;
}

.white-one-line:first-child .white-one-img,
.white-one-line:nth-child(2) .white-one-box,
.white-one-line:last-child .white-one-img {
  position: absolute;
  right: 0;
}

.white-one-img {
  transition: 0.3s;
}

.white-one-img:hover {
  opacity: 0.8;
}

.white-one-line img,
.white-one-box {
  display: inline-block;
}

.white-one-line img {
  width: 48%;
  height: 100%;
}

.white-one-box {
  width: 50%;
  height: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.white-one-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.white-one-title {
  line-height: 50px;
  color: #fff;
  background: linear-gradient(0deg, #458f42, #458f42, #458f42);
  font-size: 20px;
  font-weight: bold;
  padding-left: 60px;
}

.white-one-en {
  display: inline-block;
  vertical-align: middle;
}

.white-one-box-inner {
  height: 228px;
  position: relative;
  color: #000;
  font-size: 18px;
  padding: 38px 60px;
}

.white-one-box-text {
  line-height: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-indent: 36px;
}

.white-one-btn {
  width: 104px;
  line-height: 35px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  text-align: center;
  transition: 0.3s;
  color: #fff;
  background-color: #32892e;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  user-select: none;
}

.white-one-btn:hover {
  opacity: 0.8;
}

.page-white-two {
  position: relative;
  text-align: center;
  background-color: #fff;
  padding-bottom: 265px;
}

.white-two-bot {
  width: 100%;
  position: absolute;
  bottom: -484px;
  margin: 76px 0 24px;
}

.white-two-bot img {
  width: 100%;
}

.white-two-bot-inner {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding-top: 85px;
}

.white-two-bot-title {
  font-size: 48px;
  font-weight: 400;
  margin-bottom: 20px;
}

.white-two-bot-btn {
  width: 155px;
  line-height: 49px;
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(0deg, #458f42, #458f42, #458f42);
  border-radius: 25px;
  font-size: 16px;
  font-weight: 400;
  margin-top: 70px;
  cursor: pointer;
  user-select: none;
}

.white-two-bot-btn:hover {
  opacity: 0.8;
}

.page-gray-one {
  width: 100%;
  height: 219px;
  background-color: #e7e6e6cc;
}
@media screen and (max-width: 600px) {
  .page-bg-one .page-inner {
    display: block;
  }

  .bg-one-inner {
    width: calc(100% - 30px);
    padding: 30px 15px;
    margin: 0;
    user-select: none;
  }

  .bg-one-inner-title {
    font-size: 33px;
  }

  .bg-one-desc {
    font-size: 18px;
    margin-top: 5px;
  }

  .bg-one-btn {
    width: 130px;
    line-height: 40px;
    margin-top: 25px;
  }

  .page-white-one {
    padding: 0;
  }

  .white-one-line {
    height: unset;
    margin-bottom: 0;
  }

  .white-one-line:first-child .white-one-img,
  .white-one-line:nth-child(2) .white-one-box,
  .white-one-line:last-child .white-one-img {
    position: relative;
  }

  .white-one-line img,
  .white-one-box {
    display: block;
  }

  .white-one-line img {
    width: 100%;
    height: unset;
  }

  .white-one-box {
    width: 100%;
    padding-bottom: 40px;
  }

  .white-one-title {
    line-height: 44px;
    font-size: 18px;
    padding-left: 15px;
  }

  .white-one-en {
    vertical-align: top;
  }

  .white-one-box-inner {
    height: unset;
    min-height: 120px;
    font-size: 16px;
    padding: 10px 15px;
  }

  .white-one-box-text {
    text-indent: 32px;
  }
  .white-one-btn {
    width: 104px;
    line-height: 35px;
    right: 10px;
    bottom: -30px;
    font-size: 14px;
  }

  .page-white-two {
    padding: 90px 0 30px;
  }

  .white-two-bot {
    height: 50vw;
    bottom: -155px;
  }

  .white-two-bot img {
    width: 98%;
    height: 100%;
    /* object-fit: cover; */
  }

  .white-two-bot-inner {
    font-size: 15px;
    padding-top: 35px;
  }

  .white-two-bot-title {
    font-size: 26px;
    margin-bottom: 2px;
  }

  .white-two-bot-btn {
    width: 130px;
    line-height: 40px;
    margin-top: 30px;
  }

  .page-gray-one {
    height: 125px;
  }
}
</style>

<template>
  <div id="cooperationPage">
    <div
      class="cooperation-banner"
      :style="{ 'background-image': 'url(' + apiUrl + banner.adImage + ')' }"
    >
      <span v-if="isCN">{{ $t("useful.sincere") }}</span>
      <span v-else class="banner-en">{{ $t("useful.sincere") }}</span>
    </div>
    <div class="cooperation-gray">
      <div class="page-inner">
        <div class="cooperation-gray-inner">
          <div class="cooperation-gray-title">
            <span v-if="isCN">合作客户</span>
            <div v-else class="cooperation-gray-title-en">Our Partners</div>
          </div>
          <div class="cooperation-part">
            <div class="cooperation-box" v-for="(item, i) in list" :key="i">
              <img :src="apiUrl + item.image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAdInfoByType, getArticleListByType } from "@/request/Api";

export default {
  name: "cooperationPage",
  data() {
    return {
      isCN: true,
      banner: {},
      list: [],
    };
  },
  mounted() {
    if (localStorage.getItem("lang") == "cn") {
      this.isCN = true;
    } else {
      this.isCN = false;
    }
    this.$EventBus.$on("changeLang", (res) => {
      if (res == "cn") {
        this.isCN = true;
      } else {
        this.isCN = false;
      }
    });

    this.getData();
  },
  methods: {
    getData() {
      getAdInfoByType({
        adType: "PARTNER",
      }).then((res) => {
        this.banner = res.data;
      });
      getArticleListByType({
        articleType: "PARTNER",
        pageSize: 99999999,
      }).then((res) => {
        this.list = res.rows;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.cooperation-banner {
  text-align: center;
  letter-spacing: 15px;
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 63px;
  padding: 236px 0 263px;
}
.banner-en {
  letter-spacing: normal;
}

.cooperation-gray {
  background-color: #f9f9f9;
  padding: 39px 0 57px;
}

.cooperation-gray-inner {
  background: url(../assets/img/cooperation-bg.png) no-repeat;
  background-size: contain;
  padding: 45px 127px 0;
}

.cooperation-gray-title {
  text-align: center;
  color: #000;
  font-size: 30px;
  margin-bottom: 80px;
}

.cooperation-gray-title-en {
  color: #00411f;
  font-size: 48px;
}

.cooperation-part {
  display: flex;
  flex-wrap: wrap;
}

.cooperation-box {
  width: calc(25% - 18px);
  height: 5vw;
  position: relative;
  transition: 0.3s;
  background-color: #fff;
  margin-right: 24px;
  margin-bottom: 20px;
}

.cooperation-box:nth-child(4n) {
  margin-right: 0;
}

.cooperation-box img {
  width: 80%;
  height: 85%;
  object-fit: contain;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.cooperation-box:hover {
  transform: scale(1.1);
  box-shadow: 1px 2px 29px 0px rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 600px) {
  .cooperation-banner {
    font-size: 32px;
    padding: 30px 0;
  }

  .cooperation-gray {
    padding: 30px 0;
  }

  .cooperation-gray-inner {
    padding: 0 15px;
  }

  .cooperation-gray-title {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .cooperation-gray-title-en {
    font-size: 28px;
  }

  .cooperation-part {
    display: block;
  }

  .cooperation-box {
    width: calc(50% - 15px);
    height: 18vw;
    display: inline-block;
    margin-bottom: 15px;
  }

  .cooperation-box:nth-child(4n) {
    margin-right: 24px;
  }

  .cooperation-box:nth-child(2n) {
    margin-right: 0;
  }
}
</style>

// 英文语言包
import enLocale from "element-ui/lib/locale/lang/en"; // 英文
export default {
  ...enLocale,
  navBar: {
    index: "Home",
    product: "Products",
    about: "Abouts",
    culture: "Corporate culture",
    factory: "Factory scenery",
    cooperation: "Cooperative clients",
    contact: "Contact us",
    main: "Main menu",
    copyright: "Copyright",
    gusher: "gusher",
    cookie: "Cookie policy",
    privacy: "Privacy policy",
    terms: "Terms And Conditions",
    commit: "Commitment to Accessibility",
    mainBusiness: "Main business",
    transparent: "Transparent PETG",
    white: "White PETG",
    custom: "Customizing your projects starting from here",
    menu: "Menu",
  },
  useful: {
    read: "READ MORE",
    view: "Views",
    proList: "Product List",
    proApply: "Product Application",
    consult: "Click To Consult",
    description: "Description",
    application: "Application",
    contact: "Contact Information",
    name: "Name",
    phone: "Phone",
    email: "E-mail",
    require: "Please state your requirements",
    submit: "Submit",
    enter: "Please enter ",
    instructions: "Product details and instructions",
    total: "",
    tiao: "in total",
    page: "Page",
    firstPage: "First Page",
    lastPage: "Last Page",
    sincere: "Sincere cooperation",
    mobile: "Mobile Phone",
    address: "Address",
    code: "QR code",
    scan: "Click to scan the code",
  },
  pro: {
    intro: "PRODUCT INTRODUCTION",
    apply: "MAIN APPLICATIONS",
    specifi: "PRODUCT SPECIFICATIONS",
    num: "NUMBER OF JOINTS",
    core: "PAPER CORE",
  },
};

import { request } from "./http";

let url = "/view/";

export function getAdInfoByType(data) {
  return request("get", url + "getAdInfoByType", data);
}
export function getSystemInfo(data) {
  return request("get", url + "getSystemInfo", data);
}
export function getArticleListByType(data) {
  return request("get", url + "getArticleListByType", data);
}
export function getProductList(data) {
  return request("get", url + "getProductList", data);
}
export function getProductById(data) {
  return request("get", url + "getProductById", data);
}
export function addConsult(data) {
  return request("post", url + "addConsult", data);
}
export function getArticleById(data) {
  return request("get", url + "getArticleById", data);
}
